import { DisplayStatuses } from '@/entities/org/DOCUMENTREVISION';
import { DataTable, DataTableHandle } from '@/ui/ui/datatable/data-table';
import { PaginationToolsForTable } from '@/ui/ui/datatable/pagination-tools';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { forwardRef } from 'react';
import { DocumentRevisionStatuses } from '@/entities/org/DOCUMENTREVISION';
import { DocumentsTableSubRow } from './DocumentsTableSubRow';
import { getDocumentsTableExtraHeaders, renderDocumentsTableExpandedRow } from './utils';
import DocumentRevisionTableToolbar from './DocumentRevisionTableToolbar';

export interface DocRevEntry {
    id: number
    revId: number,
    revNo: string,
    title: string,
    // The statusId is used to determine the icon to display in expander column.
    statusId: number,
    // status is for the actual status column
    status: string,
    docType: string,
    owner: string,
    officialDate: Date | undefined,
    blobId: number | undefined,
    // optional columns for the search feature.
    orgName: string | undefined
    department: string | undefined
    orgId: number | undefined
    orgPrefix: string | undefined
    // Set status filter to official only by default.
    officialOnly?: boolean
    // for what's new - we have pending documents that have approval sessions.
    // There are also different types of documents, like official, draft, and archive.
    // We need to know the applicable date for each type.
    applicableDate?: Date,
    // For docrev tables where we want to show the manual title(s) associated with the document.
    manuals?: string[],
}

interface DocumentRevisionTableProps {
    docRevs: DocRevEntry[],
    contentFilter?: string,
    enableOrgColumn?: boolean,
    enableDepartmentColumn?: boolean,
    sorting?: SortingState
    onSortingChange?: React.Dispatch<React.SetStateAction<SortingState>>,
    columns: ColumnDef<DocRevEntry>[]
    manualMode?: boolean,
    loading?: boolean
    exportAsFileName: string,
    limitTableHeight: boolean,
    officialOnly?: boolean
}



const DocumentRevisionTableInner = ({
    docRevs,
    contentFilter,
    sorting,
    onSortingChange,
    columns,
    loading = false,
    manualMode = false,
    exportAsFileName,
    limitTableHeight,
    officialOnly,
}: DocumentRevisionTableProps, ref: React.Ref<DataTableHandle<DocRevEntry>>) => {
    return (
        <div>
            <DataTable
                exportAsFileName={exportAsFileName}
                loading={loading}
                renderRowSubComponent={(row, isExporting) => {
                    if (isExporting) {
                        return null;
                    }

                    // We don't support content filters unless the table is in manual filtering mode.
                    // content searches have to be on server side.
                    return <DocumentsTableSubRow row={row} contentFilter={contentFilter} />;
                }}
                toolbar={!manualMode ? DocumentRevisionTableToolbar : undefined}
                pagination={!manualMode ? PaginationToolsForTable : undefined}
                columns={columns}
                data={docRevs}
                sorting={sorting}
                onSortingChange={onSortingChange}
                ref={ref}
                limitTableHeight={limitTableHeight}
                initialFilterColumns={officialOnly ?
                    [
                        {
                            id: 'status',
                            value: [
                                DisplayStatuses[DocumentRevisionStatuses.official],
                            ]
                        }
                    ] : undefined
                }
                renderExpandedRowForTableExport={(entry, exportType) => {
                    return renderDocumentsTableExpandedRow(entry,
                        getDocumentsTableExtraHeaders(!!contentFilter),
                        contentFilter ?? '', exportType);
                }}
            />
        </div>
    );
};

export const DocumentRevisionTable = forwardRef<DataTableHandle<DocRevEntry>, DocumentRevisionTableProps>(DocumentRevisionTableInner);
