import { INFORMATION_HIERARCHY, INFORMATION_HIERARCHY_WITH_ORG, ManualGroup } from '@/entities/org/INFORMATION_HIERARCHY';
import React, { useContext } from 'react';
import useSWR from 'swr';
import { InformationHierachyBrowser } from '../../components/informationhierarchy/InformationHierachyBrowser';
import UserBundleContext from '@/context/UserBundleContext';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';

interface ManualsProps {

}


export interface INFORMATION_HIERARCHY_WITH_ORG_INFO extends INFORMATION_HIERARCHY_WITH_ORG {
    organizationName: string;
    isParentOrg: boolean;
}

export const useOrgManuals = () => {
    const { data: manuals, isLoading } = useSWR<{ manualGroups: ManualGroup[] }>('/api/homepage/card/manuals');
    const { data: parentManuals } = useSWR<{ manualGroups: ManualGroup[] }>('/api/homepage/card/manuals/parent');

    // map manual groups and turn the publishers into psuedo information hierarchies
    const mapManualGroups = () => {
        if (!manuals || !parentManuals) {
            return [];
        }

        const attachOrgIds = (ihs: INFORMATION_HIERARCHY[], orgId: number) => {
            for (const ih of ihs) {
                (ih as INFORMATION_HIERARCHY_WITH_ORG).ORG_ID = orgId;
                if (ih.CHILDREN) {
                    attachOrgIds(ih.CHILDREN, orgId);
                }
            }

            return ihs as INFORMATION_HIERARCHY_WITH_ORG[];
        };

        const allManuals = [...manuals.manualGroups, ...parentManuals.manualGroups];
        return allManuals.map((manualGroup, index) => {
            const entry: INFORMATION_HIERARCHY_WITH_ORG_INFO = {
                organizationName: manualGroup.organizationName,
                ORG_ID: manualGroup.organizationId,
                isParentOrg: manualGroup.isParentOrg,

                INFORMATION_HIERARCHY_ID: index,
                TITLE: manualGroup.publisher.TITLE,
                CHILDREN: attachOrgIds(manualGroup.informationHierarchies, manualGroup.organizationId),
                // Set rest to nullish values
                CODE: null,
                RECURSION_LEVEL_ID: 0,
                USERID: 0,
                USER: null,
                PARENTID: null,
                PUBLISHER_ID: 0,
                PRINTABLE: null,
                ACCESSLEVEL: null,
                VIEWCONTROL: false,
                SHOWAPPLICABLE: null,
                INHERITCOLLABORATION: false,
                INHERITVISIBILITY: false,
                PROPERTYHASH: null,
                LAST_EDITED_DATE: null,
                IMAGE_BLOBID: null,
                IMAGE: null,
                STATUS: '',
                ORDEREDREVIEW: null,
                DELAYED_RATIFICATION: null,
                VISIBLE_ON_FACILITY_HOMEPAGES: null,
                APPROVAL_ROUTE_TEMPLATES: null,
                APPROVAL_SESSIONS: null,
                SIGNATURE_SESSIONS: null,
                COMMITTEE_APPROVAL_SESSIONS: null,
                DOCUMENT: null,
                DOC_REQUEST_RECEIVED_TYPES: null,
                ACL: null,
                INFORMATION_INDEXES: null,
                SIGNERASSIGNMENTS: null,
                ROUTING_CONFIGURATIONS: null,
                COMMITTEE_ROUTING_LOG_DETAILS: null,
                PUBLISHER: null,
                ACK_SCHEDULES: null,
                PARENT: null,
                VIEWERS: null,
                COLLABORATORS: null,
                INHERIT_ACLS: [],
            };

            return entry;
        });
    };


    return { loading: isLoading, manuals: mapManualGroups() };
};

export const Manuals: React.FC<ManualsProps> = () => {
    const userBundle = useContext(UserBundleContext);

    const { loading , manuals } = useOrgManuals();

    const baseURL = useOrgBaseURL();

    return (
        <InformationHierachyBrowser
            expandAll={userBundle.preferences.manualsExpandedByDefault}
            browserHierarchies={manuals}
            breadcrumb={{ title: userBundle.organization?.MANUALNAMEPLURAL ?? 'Manuals', href: `${baseURL}/dashboard?homepageTab=manuals` }}
            ihType='manual'
            loading={loading}
        />
    );
};