import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryColumn,
} from 'typeorm';
import { COMMITTEE_ROUTING_LOG,  } from './COMMITTEE_ROUTING_LOG';
import { DOCUMENTREVISION,  } from './DOCUMENTREVISION';
import { INFORMATION_HIERARCHY,  } from './INFORMATION_HIERARCHY';
import { SchemaEntityManager } from '../SchemaEntityManager';

// Factory function to create COMMITTEE_ROUTING_LOG_DETAIL entity with dynamic schema
function createCommitteeRoutingLogDetailEntity(schema: string, manager: SchemaEntityManager) {
    @Entity('COMMITTEE_ROUTING_LOG_DETAIL', { schema: schema })
    class COMMITTEE_ROUTING_LOG_DETAIL {
        static SCHEMANAME = schema;

        @PrimaryColumn(
            'number',
            {
                nullable: false,
                name: 'COMMITTEE_ROUTING_LOG_DETAIL_ID'
            }
        )
        COMMITTEE_ROUTING_LOG_DETAIL_ID!: number;

        @Column(
            'number',
            {
                nullable: false,
                name: 'ROUTING_ID'
            }
        )
        ROUTING_ID!: number;

        @ManyToOne(
            () => manager.getCommitteeRoutingLogEntity(schema), 
            (committeeRoutingLog) => committeeRoutingLog.COMMITTEE_ROUTING_LOG_DETAILS
        )
        @JoinColumn({
            name: 'ROUTING_ID',
        })
        COMMITTEE_ROUTING_LOG!: COMMITTEE_ROUTING_LOG | null;

        @Column(
            'number',
            {
                nullable: true,
                name: 'DOCREVID'
            }
        )
        DOCREVID!: number | null;

        @ManyToOne(
            () => manager.getDocumentRevisionEntity(schema), 
            (documentRevision) => documentRevision.COMMITTEE_ROUTING_LOG_DETAILS
        )
        @JoinColumn({
            name: 'DOCREVID'
        })
        DOCUMENTREVISION!: DOCUMENTREVISION | null;

        @Column(
            'number',
            {
                nullable: true,
                name: 'MANUALID'
            }
        )
        MANUALID!: number | null;

        @ManyToOne(
            () => manager.getInformationHierarchyEntity(schema), 
            (informationHierarchy) => informationHierarchy.COMMITTEE_ROUTING_LOG_DETAILS
        )
        @JoinColumn({
            name: 'MANUALID'
        })
        MANUAL!: INFORMATION_HIERARCHY | null;

        @Column(
            'clob',
            {
                nullable: false,
                name: 'ROUTING_CONFIGURATION'
            }
        )
        ROUTING_CONFIGURATION!: string;

        @Column(
            'varchar2',
            {
                nullable: true,
                name: 'SIGNERASSIGNMENT'
            }
        )
        SIGNERASSIGNMENT!: string | null;

        @Column(
            'varchar2',
            {
                nullable: true,
                name: 'APPROVAL_ROUTE_TEMPLATE'
            }
        )
        APPROVAL_ROUTE_TEMPLATE!: string | null;
    }

    return COMMITTEE_ROUTING_LOG_DETAIL;
}

// Export the factory function
export { createCommitteeRoutingLogDetailEntity };

// Define and export the type for instances created by the factory function
export type COMMITTEE_ROUTING_LOG_DETAIL = InstanceType<ReturnType<typeof createCommitteeRoutingLogDetailEntity>>;
