import { Preferences } from '@/_shared/utils/preferences-defs';
import UserBundleContext from '@/context/UserBundleContext';
import { Button } from '@/ui/ui/button';
import { Input } from '@/ui/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/ui/ui/select';
import { FieldApi, useForm } from '@tanstack/react-form';
import axios from 'axios';
import { SearchIcon } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import { ResetPreferencesDialog } from './ResetPreferencesDialog';
import useSWR from 'swr';
import { cn } from '@/lib/utils';
import hasRights from '@/_shared/utils/hasRights';
import { RightsCodeKey } from '@/entities/master/PERSON';
import { Tooltip, TooltipProvider, TooltipContent, TooltipTrigger } from '@/ui/ui/tooltip';
import { HelpTopicContext } from '@/context/HelpTopicContext';
import { helpTopicDocIDs } from '@/_shared/constants/HelpSystemIDs';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

interface PreferencesProps {
    // user mode edits for a specific user, organization mode edits for the organization.
    // Permissions are checked on the server of course.
    mode: 'user' | 'organization';
}



export const PreferencesEditor: React.FC<PreferencesProps> = ({ mode }) => {
    const userBundle = useContext(UserBundleContext);
    const { setHelpTopicID } = useContext(HelpTopicContext);

    useDocumentTitle('Preferences');

    const [prefs, setPrefs] = useState<Preferences>(userBundle.preferences);

    const { data: hasCommitteesData, } = useSWR('/api/committee/has-committees');
    const { data: hasAccManPrivilegesData } = useSWR({
        url: '/ajax/accreditation_manager.pl',
        params: {
            action: 'has_any_privileges'
        }
    });

    // const hasMyEducation = userBundle.organization?.MY_EDUCATION_IS_ENABLED && hasRights(userBundle, RightsCodeKey.Reader);

    const isAtleastSigner = hasRights(userBundle, RightsCodeKey.Signer);


    const hasCommittees = hasCommitteesData?.hasCommittees === true;
    const hasAccManPrivileges = hasAccManPrivilegesData?.hasAccManPrivileges === 1;

    useEffect(() => {
        const fetchPrefs = async () => {
            const resp = await axios.get('/api/preferences/get-org-preferences');

            if (resp.data) {
                setPrefs(resp.data.success.preferences);
            }
        };

        if (mode === 'organization') {
            fetchPrefs();
        }

        if (hasRights(userBundle, RightsCodeKey.UserAdministrator)) {
            setHelpTopicID(helpTopicDocIDs.ADM_HOME_PREFERENCES);
        } else {
            setHelpTopicID(helpTopicDocIDs.HOME_PREFERENCES);

        }
    }, [mode]);

    const form = useForm({
        defaultValues: prefs,
        onSubmit: async (values) => {
            await axios.post('/api/preferences/update', {
                preferences: values.value,
                mode
            });

            // Reloading the window is the easiest way to update the preferences.
            // This is because the preferences are stored in the context, and the context is not update-able.
            window.location.reload();
        }
    });

    return (
        <div className='flex p-4 flex-col'>
            <form
                className='flex flex-col'
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    form.handleSubmit();
                }}
            >
                <p className='text-2xl text-primary font-semibold'>Preferences</p>
                <div className='flex flex-row my-2'>
                    <Input placeholder='Search preferences...' icon={<SearchIcon size={16} />} />
                    <Button variant={'outline'} size={'sm'} className='ml-2' type='submit'>Save</Button>
                    <ResetPreferencesDialog mode={mode} />
                </div>
                <Section title='General'>
                    <PreferenceOption mode={mode} title={'Dashboard Tab'} description={'Select the default tab users will see on login.'}>
                        <form.Field
                            name='homepageTab'
                            children={(field) => {
                                return <Select
                                    // v is always a string, and the value of the select is parsed.
                                    onValueChange={(v) => field.setValue(v as any)}
                                    value={field.getValue()}
                                >
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="overview">Overview</SelectItem>
                                        <SelectItem value="departments">Departments</SelectItem>
                                        <SelectItem value="manuals">Manuals</SelectItem>
                                    </SelectContent>
                                </Select>;
                            }}
                        />
                    </PreferenceOption>
                    <PreferenceOption mode={mode} title={'Landing Page'} roleBasedOption description={'Select the feature that you want to see when you first log in.'}>
                        <form.Field
                            name='landingPageFeature'
                            children={(field) => {
                                return <Select
                                    // v is always a string, and the value of the select is parsed.
                                    onValueChange={(v) => field.setValue(v as any)}
                                    value={field.getValue()}
                                >
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="dashboard">Dashboard</SelectItem>
                                        {isAtleastSigner && <SelectItem value="docmanager">DocManager</SelectItem>}
                                        {hasCommittees && <SelectItem value="committees">Committees</SelectItem>}
                                        {hasAccManPrivileges && <SelectItem value="accreditation-manager">Accreditation Manager</SelectItem>}
                                        {/* See LUCIDOC-3469 for why My Education is disabled. */}
                                        {/* {hasMyEducation && <SelectItem value="my-education">My Education</SelectItem>} */}
                                    </SelectContent>
                                </Select>;
                            }}
                        />
                    </PreferenceOption>
                    <PreferenceOption mode={mode} title={'Theme'} description={'Select the application theme.'}>
                        <form.Field
                            name='theme'
                            children={(field) => {
                                return <Select
                                    // v is always a string, and the value of the select is parsed.
                                    onValueChange={(v) => field.setValue(v as any)}
                                    value={field.getValue()}
                                >
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="light">Light</SelectItem>
                                        <SelectItem value="dark">Dark</SelectItem>
                                        <SelectItem value="system">System</SelectItem>
                                    </SelectContent>
                                </Select>;
                            }}
                        />
                    </PreferenceOption>
                    <PreferenceOption mode={mode} title={'Show Manuals Expanded'} description={'All manuals will be expanded by default'}>
                        <form.Field
                            name='manualsExpandedByDefault'
                            children={(field) => {
                                return <YesNoSelect field={field} />;
                            }}
                        />
                    </PreferenceOption>
                    <PreferenceOption mode={mode} title={'Show Departments Expanded'} description={'All departments will be expanded by default'}>
                        <form.Field
                            name='departmentsExpandedByDefault'
                            children={(field) => {
                                return <YesNoSelect field={field} />;
                            }}
                        />
                    </PreferenceOption>
                   
                </Section>
                <Section title={'Search'}>
                <PreferenceOption mode={mode} title={'Show Manuals Column By Default'} description={'All search results will show the manual they are attached to'}>
                        <form.Field
                            name='searchManualColumnShownByDefault'
                            children={(field) => {
                                return <YesNoSelect field={field} />;
                            }}
                        />
                    </PreferenceOption>
                    <PreferenceOption mode={mode} title={'Show Departments Column By Default'} description={'All search results will show the department they are attached to'}>
                        <form.Field
                            name='searchDepartmentColumnShownByDefault'
                            children={(field) => {
                                return <YesNoSelect field={field} />;
                            }}
                        />
                    </PreferenceOption>
                </Section>
            </form>
        </div>
    );
};

type YesNoSelectProps<K extends keyof Preferences> = {
    field: FieldApi<Preferences, K, undefined, undefined, any>;
};

const YesNoSelect = <K extends keyof Preferences>({ field }: YesNoSelectProps<K>) => {
    return (
        <Select
            onValueChange={(v) => field.setValue(v === 'yes')}
            value={field.getValue() ? 'yes' : 'no'}
        >
            <SelectTrigger className="w-[180px]">
                <SelectValue />
            </SelectTrigger>
            <SelectContent>
                <SelectItem value="yes">Yes</SelectItem>
                <SelectItem value="no">No</SelectItem>
            </SelectContent>
        </Select>
    );
};
interface PreferenceOptionProps {
    title: string;
    description: string;
    children: React.ReactNode;
    roleBasedOption?: boolean;
    mode: 'user' | 'organization';
}

const PreferenceOption: React.FC<PreferenceOptionProps> = ({ title, description, children, mode, roleBasedOption = false }) => {

    if (roleBasedOption && mode === 'user') {
        roleBasedOption = false;
    }

    return <TooltipProvider>
        <Tooltip>
            <TooltipTrigger asChild>
                <div className={cn(
                    'flex flex-col w-full justify-between my-1 max-w-[60rem] xs:flex-row',
                    roleBasedOption && 'opacity-50'
                )}>
                    <div className='flex flex-col mb-2 xs:mb-0'>
                        <p className='text-md font-semibold text-primary'>{title}</p>
                        <p className='text-sm font-normal text-muted-foreground'>{description}</p>
                    </div>
                    <div className={cn(roleBasedOption && 'pointer-events-none')}>
                        {children}
                    </div>
                </div>
            </TooltipTrigger>
            {roleBasedOption && <TooltipContent side={'bottom'}>
                <p>You cannot set a default for a role based preference.</p>
            </TooltipContent>}
        </Tooltip>
    </TooltipProvider>;
};


interface SectionProps {
    title: string;
    children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ title, children }) => {
    return <div className='w-full flex flex-col pr-4 mb-4'>
        <p className='text-muted-foreground uppercase font-semibold text-xs'>{title}</p>
        {/* Horizontal Line */}
        <div className='w-full h-1 border-b border-border'></div>
        {children}
    </div>;
};