import { DOCUMENTREVISION, DisplayStatuses } from '@/entities/org/DOCUMENTREVISION';
import { QueryParams, useBrowserQuery } from '@/hooks/useBrowserQuery';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DocRevEntry, DocumentRevisionTable } from '../../components/documentrevisiontable/DocumentRevisionTable';
import { InputWithLabel } from '@/ui/ui/input';
import { PaginationTools } from '@/ui/ui/datatable/pagination-tools';
import axios from 'axios';
import { LoadingStatuses } from '@/utils/LoadingStatuses';
import { FacetedFilter } from '@/ui/ui/facteted-filter';
import { forceArrayForQueryString } from '@/utils/forceArray';
import { UnknownStatusShadowIcon } from '@/ui/icons/shadow/UnknownStatusShadow';
import { useForm } from '@tanstack/react-form';
import { Button } from '@/ui/ui/button';
import { useDocumentTypes } from '@/hooks/useDocumentTypes';
import { DocumentTypeShadowIcon } from '@/ui/icons/shadow/DocumentTypeShadow';
import { UserShadowIcon } from '@/ui/icons/shadow/UserShadow';
import useUserSearch from '@/hooks/useUserSearch';
import useDebounce from '@/hooks/useDebounce';
import { PersonStatuses } from '@/entities/master/PERSON';
import { useIsUserLoggedInToAMultiOrg, useMultiOrgSubscriberOrgs } from '@/hooks/useIsUserLoggedInToAMultiOrg';
import { NavigationDropdownSectionEntryLabel } from '@/ui/ui/navigation-dropdown';
import { Checkbox } from '@/ui/ui/checkbox';
import { BuildingsOutlineIcon } from '@/ui/icons/outline/BuildingsOutline';
import { SortingState } from '@tanstack/react-table';
import { DepartmentShadowIcon } from '@/ui/icons/shadow/DepartmentShadow';
import { InformationHierarchyFacetedFilter } from '@/ui/ui/infohierarchy-faceted-filter';
import { INFORMATION_HIERARCHIES_PARENT } from '@/entities/org/INFORMATION_HIERARCHY';
import useSWR from 'swr';
import { ServerSideViewOptions } from '@/ui/ui/datatable/serverside-datatable-view-options';
import { DataTableColumnHeader } from '@/ui/ui/datatable/column-header';
import UserBundleContext, { UserBundleType } from '@/context/UserBundleContext';
import { DataTableHandle } from '@/ui/ui/datatable/data-table';
import { FacetedFilterDocumentStatuses, getDocumentsTableExtraHeaders } from '../../components/documentrevisiontable/utils';
import fixOfficialDocumentLinks, { useDocumentRevisionTableColumns } from '@/v2/components/documentrevisiontable/DocumentRevisionTableColumns';
import { constructFullName } from '@/components/UserPicker/utils/constructFullName';
import { useLocation } from 'react-router-dom';
import { useOrgManuals } from '../homepage/Manuals';


interface SearchProps {

}

export interface SearchQuery extends QueryParams {
    title: string;
    documentId: string;
    content: string;
    documentTypeDescriptions: string[];
    documentStatusIds: string[];
    documentOwnerIds: string[];
    multiOrgSearchOrgIds: string[];
    departmentIds: string[];
    manualIds: string[];
    isMultiOrg: boolean;
    pageSize: number;
    pageCount: number;
}

export const isOnlyReader = (userBundle: UserBundleType) => {
    const user = userBundle.user?.RIGHTSINENGLISH;
    if (user) {
        return user.reader && !user.signer && !user.author && !user.courseWriter && !user.documentAdministrator && !user.userAdministrator && !user.organizationAdministrator && !user.systemAdministrator;
    } else return false;
};

export const Search2: React.FC<SearchProps> = () => {
    const [query, setQuery] = useBrowserQuery<SearchQuery>();

    const userBundle = useContext(UserBundleContext);

    const subscriberOrgs = useMultiOrgSubscriberOrgs(true);
    const [sorting, setSorting] = useState<SortingState>([]);

    const form = useForm({
        defaultValues: {
            title: query.title,
            documentId: query.documentId,
            content: query.content,
            documentTypeDescriptions: new Set<string>(forceArrayForQueryString(query.documentTypeDescriptions)),
            documentStatusIds: new Set<string>(forceArrayForQueryString(query.documentStatusIds)),
            documentOwnerIds: new Set<string>(forceArrayForQueryString(query.documentOwnerIds)),
            multiOrgSearchOrgIds: new Set<string>(forceArrayForQueryString(query.multiOrgSearchOrgIds)),
            departmentIds: new Set<string>(forceArrayForQueryString(query.departmentIds)),
            manualIds: new Set<string>(forceArrayForQueryString(query.manualIds)),
            isMultiOrg: query.isMultiOrg,
            pageSize: query.pageSize,
            pageCount: query.pageCount,
        },
        onSubmit: (f) => {
            const finalVals = {
                ...f.value,
                documentTypeDescriptions: Array.from(f.value.documentTypeDescriptions),
                documentStatusIds: Array.from(f.value.documentStatusIds),
                documentOwnerIds: Array.from(f.value.documentOwnerIds),
                multiOrgSearchOrgIds: Array.from(f.value.multiOrgSearchOrgIds),
                departmentIds: Array.from(f.value.departmentIds),
                manualIds: Array.from(f.value.manualIds),
                pageCount: 1,
            };

            setQuery(finalVals);
            fetchDocumentsAndSetSearchResp(finalVals, false);
        }
    });

    const pageCount = query.pageCount ?? 1;
    const pageSize = query.pageSize ?? 10;

    interface SearchResponse {
        docrevs: DOCUMENTREVISION[];
        docCount: number;
    }


    const { canDoMultiOrgSearches } = useIsUserLoggedInToAMultiOrg();



    const [searchRes, setSearchResp] = useState<SearchResponse | undefined>(undefined);
    const docRevs = searchRes?.docrevs;
    const [loading, setLoading] = useState<LoadingStatuses>(LoadingStatuses.MOUNTING);
    // const debouncedFetchDocuments = useCallback(debounce((query: SearchQuery) => {
    //     fetchDocuments(query);
    // }, 800), []);

    const fetchDocuments = async (query: SearchQuery, exportMode: boolean) => {
        setLoading(LoadingStatuses.LOADING);
        const statuses = forceArrayForQueryString(query.documentStatusIds).map(v => parseInt(v));
        const docTypes = forceArrayForQueryString(query.documentTypeDescriptions).map(v => v.toString());
        const ownerIds = forceArrayForQueryString(query.documentOwnerIds).map(v => parseInt(v));
        const multiOrgSearchOrgIds = forceArrayForQueryString(query.multiOrgSearchOrgIds).map(v => parseInt(v));
        const departmentIds = forceArrayForQueryString(query.departmentIds);
        const manualIds = forceArrayForQueryString(query.manualIds);

        if (!query.title
            && !query.content
            && !query.documentId
            && statuses.length === 0
            && docTypes.length === 0
            && ownerIds.length === 0
            && multiOrgSearchOrgIds.length === 0
            && departmentIds.length === 0
            && manualIds.length === 0
        ) {
            setSearchResp({
                docrevs: [],
                docCount: 0
            });
            setLoading(LoadingStatuses.READY);
            return;
        }
        // I know this looks weird.
        // The table only supports one sorting field, so we only take the first one.
        // For some reason tanstack's table has an array for the type for SortingState.
        // I also dont want to bother doing multiple layers of sorting anyways.
        const sortPayload = sorting?.map(s => {
            return {
                field: s.id,
                direction: s.desc ? 'desc' : 'asc'
            };
        })[0] ?? undefined;


        const depts: { [key: number]: number[] } = {};
        const manuals: { [key: number]: number[] } = {};
        const convertToObject = (ihIds: string[], target: { [key: number]: number[] }) => {
            ihIds.forEach((id) => {
                // We need to turn both Ih IDs into a object instead of the string array which is "ORGID-DEPTID" right now.
                const [orgId, IHId] = id.toString().split('-').map(v => {
                    // value is like org:123, or ih:123
                    const [, value] = v.split(':');
                    return parseInt(value);
                });
                if (target[orgId] === undefined) {
                    target[orgId] = [];
                }
                target[orgId].push(IHId);
            });
        };
        convertToObject(departmentIds, depts);
        convertToObject(manualIds, manuals);

        const res = await axios.post('/api/homepage/search', {
            // Have to force .toString just in case. On prod builds any valid number is converted into number type, but not on dev.
            title: query.title?.toString() || undefined,
            content: query.content?.toString() || undefined,
            id: query.documentId ? query.documentId.toString() : undefined,
            documentStatusIds: statuses.length > 0 ? statuses : undefined,
            documentTypeDescriptions: docTypes.length > 0 ? docTypes : undefined,
            documentOwnerIds: ownerIds.length > 0 ? ownerIds : undefined,
            departmentIds: departmentIds.length > 0 ? depts : undefined,
            manualIds: manualIds.length > 0 ? manuals : undefined,
            isMultiOrgSearch: query.isMultiOrg ?? false,
            multiOrgSearchOrgIds: multiOrgSearchOrgIds.length > 0 ? multiOrgSearchOrgIds : undefined,
            pageCount: query.pageCount || 1,
            pageSize: query.pageSize || 10,
            sorting: sortPayload,
            exportMode: exportMode
        });

        return res.data;
    };


    const fetchDocumentsAndSetSearchResp = async (query: SearchQuery, exportMode: boolean) => {
        const res = await fetchDocuments(query, exportMode);
        setSearchResp(res);
        setLoading(LoadingStatuses.READY);
        const searchTabTitle = ['Search -'];
        if (query.title) searchTabTitle.push(`title: ${query.title}`);
        if (query.content) searchTabTitle.push(`content: ${query.content}`);
        document.title = searchTabTitle.join(' ');
    };

    const location = useLocation();

    useEffect(() => {
        // console.log('triggered page effect.')
        // On the first load, this one runs.
        // This effect is to stop debouncing when changing page or page size.
        fetchDocumentsAndSetSearchResp(query, false);
    }, [query.pageSize, query.pageCount, sorting, location]);

    const getDocRevEntries = (dr: DOCUMENTREVISION[]): DocRevEntry[] => {
        if (dr === undefined) return [];


        return dr.map(docrev => {
            const manualTitles: string[] = [];

            if (docrev.DOCUMENT.INFORMATION_INDEXES) {
                for (const index of docrev.DOCUMENT.INFORMATION_INDEXES) {
                    const indexManualTitle = index.INFORMATION_HIERARCHY?.TITLE;
                    if (indexManualTitle) {
                        manualTitles.push(indexManualTitle);
                    }
                }
            }

            const entry: DocRevEntry = {
                id: docrev.DOCID,
                revId: docrev.DOCREVID,
                revNo: docrev.REVNO,
                title: docrev.TITLE || 'no title',
                statusId: docrev.DOCSTATUS_ID,
                status: DisplayStatuses[docrev.DOCSTATUS_ID],
                docType: docrev.DOCTYPE?.DESCRIPTION || 'no doc type',
                owner: docrev.DOCUMENT.DOCOWNER ? constructFullName(docrev.DOCUMENT.DOCOWNER, 'lastFirstMiddle') : '',
                officialDate: docrev.OFFICIALDT ? new Date(docrev.OFFICIALDT) : undefined,
                blobId: (docrev as any).BLOBID,
                orgName: docrev.ORGANIZATION?.NAME ?? undefined,
                orgId: docrev.ORGANIZATION?.ORGANIZATIONID ?? -1,
                orgPrefix: docrev.ORGANIZATION?.PREFIX ?? undefined,
                department: docrev.DOCUMENT.INFORMATION_HIERARCHY?.TITLE ?? undefined,
                manuals: manualTitles
            };

            return entry;
        });
    };

    const docTypes = useDocumentTypes();
    const docTypesOptions = docTypes?.map(docType => {
        return {
            label: docType.DESCRIPTION,
            value: docType.DESCRIPTION
        };
    }) || [];

    const [ownerFilterText, setOwnerFilterText] = useState<string>('');
    const debouncedOwnerFilterText = useDebounce<string>(ownerFilterText, 300);

    const [users] = useUserSearch(
        debouncedOwnerFilterText,
        Array.from(form.state.values.documentOwnerIds)
            .map((u) => parseInt(u)),
        [PersonStatuses.ACTIVE],
        undefined,
        undefined,
        undefined,
        true,
    );

    // This value is always up to date even before submission.
    // compared to doing form.state.values which only updates AFTER submission.
    const isMultiOrg = form.useStore((state) => state.values.isMultiOrg);
    // Have to turn it to an array, because a Set will won't be able to be diffed properly.
    const multiOrgSearchOrgIds = form.useStore((state) => Array.from(state.values.multiOrgSearchOrgIds));


    // singular name result is from regular search departments, the plural is from multiorg search endpoint.
    interface Departments {
        parent: INFORMATION_HIERARCHIES_PARENT,
        parents: INFORMATION_HIERARCHIES_PARENT[]
    }
    const { data: departmentsData } = useSWR<Departments>((canDoMultiOrgSearches && isMultiOrg) ? '/api/_shared/multiorg/departments' : '/api/homepage/card/departments');
    const { manuals } = useOrgManuals();
    // we need to convert the manuals which is INFORMATION_HIERARCHY_WITH_ORG_INFO[] to a INFORMATION_HIERARCHY_PARENT
    const manualsParent: INFORMATION_HIERARCHIES_PARENT = {
        TITLE: userBundle.organization?.NAME ?? 'no org name',
        CHILDREN: manuals,
        ORGID: userBundle.organization?.ORGANIZATIONID ?? -1
    };



    const columns = fixOfficialDocumentLinks(userBundle, useDocumentRevisionTableColumns());

    // preference is checked on backend and information is automatically added if enabled even if no filter is present
    if (form.state.values.departmentIds.size > 0 || userBundle.preferences.searchDepartmentColumnShownByDefault) {
        // push to 2nd index
        columns.splice(2, 0, {
            accessorKey: 'department',
            enableSorting: true,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Department' />
                );
            },
            cell: ({ cell }) => {
                return <p>{cell.getValue<string>()}</p>;
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Department'
            }
        });
    }

    // preference is checked on backend and information is automatically added if enabled even if no filter is present
    if (form.state.values.manualIds.size > 0 || userBundle.preferences.searchManualColumnShownByDefault) {
        columns.push({
            accessorKey: 'manuals',
            enableSorting: true,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title={userBundle.organization?.MANUALNAMEPLURAL ?? 'Manuals'} />
                );
            },
            cell: ({ cell }) => {
                return <p className='max-w-32'>{cell.getValue<string[]>().join(', ')}</p>;
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: userBundle.organization?.MANUALNAMEPLURAL ?? 'Manuals'
            }
        });
    }

    if (form.state.values.isMultiOrg) {
        // push to 2nd index
        columns.splice(2, 0, {
            accessorKey: 'orgName',
            enableSorting: true,
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title='Organization' />
                );
            },
            cell: ({ cell }) => {
                return <p>{cell.getValue<string>()}</p>;
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
            meta: {
                label: 'Organization'
            }
        });
    }

    

    const getMultiOrgDepartmentOptions = () => {
        if (departmentsData?.parents === undefined) return [];

        if (multiOrgSearchOrgIds.length === 0) {
            return departmentsData.parents;
        }

        return departmentsData.parents.filter(p =>
            multiOrgSearchOrgIds.includes(p.ORGID.toString())
        );
    };


    const dataTableRef = useRef<DataTableHandle<DocRevEntry>>(null);
    return (
        <>
            <div className='px-2 sm:px-4 w-full h-full'>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    form.handleSubmit();
                }}
                    className='flex flex-col mt-4 mb-2 justify-between items-start space-y-2 w-full'>
                    <div className='flex flex-col sm:flex-row items-between w-full align-end'>
                        <div className='flex flex-col w-full items-start space-y-4 lg:space-y-0 lg:space-x-4 lg:flex-row lg:w-full'>
                            <div className='flex flex-col w-full items-center space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4'>
                                <form.Field name={'title'}>
                                    {(field) => <InputWithLabel
                                        placeholder='Filter by title/keywords'
                                        type={'search'}
                                        name={field.name}
                                        value={field.state.value}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                        label='Title/Keywords'
                                        autoFocus
                                    />}
                                </form.Field>
                                <form.Field name={'content'}>
                                    {(field) => <InputWithLabel
                                        placeholder='Filter by content'
                                        type={'search'}
                                        name={field.name}
                                        value={field.state.value}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                        label='Content'
                                    />}
                                </form.Field>
                                <form.Field name={'documentId'}>
                                    {(field) => <InputWithLabel
                                        placeholder='Filter by ID'
                                        type='search'
                                        name={field.name}
                                        value={field.state.value}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                        label='Doc ID'
                                    />}
                                </form.Field>
                            </div>
                            <div className='flex flex-col space-y-4 w-full sm:w-auto sm:space-y-0 sm:flex-row sm:items-end sm:space-x-4'>
                                {!isOnlyReader(userBundle) && <form.Field name={'documentStatusIds'}>
                                    {(field) => <div className='w-full'>
                                        <p className='text-xs font-semibold uppercase text-secondary'>Status</p>
                                        <FacetedFilter
                                            title='Status'
                                            size={'sm'}
                                            options={FacetedFilterDocumentStatuses}
                                            icon={<UnknownStatusShadowIcon size={16} />}
                                            selectedValues={field.state.value}
                                            setSelectedValues={(v) => field.handleChange(v)}
                                        />
                                    </div>}
                                </form.Field>}
                                <form.Field name={'documentTypeDescriptions'}>
                                    {(field) => <div>
                                        <p className='text-xs font-semibold uppercase text-secondary'>Type</p>
                                        <FacetedFilter
                                            title='Document Types'
                                            size={'sm'}
                                            options={docTypesOptions}
                                            icon={<DocumentTypeShadowIcon size={16} />}
                                            selectedValues={field.state.value}
                                            setSelectedValues={(v) => field.handleChange(v)}
                                        />
                                    </div>}
                                </form.Field>
                                <form.Field name={'documentOwnerIds'}>
                                    {(field) => <div>
                                        <p className='text-xs font-semibold uppercase text-secondary'>Owner</p>
                                        <FacetedFilter
                                            title='Owner'
                                            size={'sm'}
                                            filterText={ownerFilterText}
                                            setFilterText={setOwnerFilterText}
                                            options={users?.map(u => {
                                                return {
                                                    label: constructFullName(u, 'firstMiddleLast'),
                                                    value: u.USERID.toString()
                                                };
                                            })}
                                            icon={<UserShadowIcon size={16} />}
                                            selectedValues={field.state.value}
                                            setSelectedValues={(v) => field.handleChange(v)}
                                        />
                                    </div>}
                                </form.Field>
                                {!isMultiOrg && <form.Field name={'departmentIds'}>
                                    {(field) => <div>
                                        <p className='text-xs font-semibold uppercase text-secondary'>Department</p>
                                        <InformationHierarchyFacetedFilter
                                            singleParentMode
                                            title='Departments'
                                            size={'sm'}
                                            options={departmentsData?.parent ? [departmentsData.parent] : []}
                                            icon={<DepartmentShadowIcon size={16} />}
                                            selectedValues={field.state.value}
                                            setSelectedValues={(v) => field.handleChange(v)}
                                        />
                                    </div>}
                                </form.Field>}
                                {!isMultiOrg && <form.Field name={'manualIds'}>
                                    {(field) => <div>
                                        <p className='text-xs font-semibold uppercase text-secondary'>Manuals</p>
                                        <InformationHierarchyFacetedFilter
                                            singleParentMode
                                            title='Manuals'
                                            size={'sm'}
                                            options={manuals ? [manualsParent] : []}
                                            icon={<DepartmentShadowIcon size={16} />}
                                            selectedValues={field.state.value}
                                            setSelectedValues={(v) => field.handleChange(v)}
                                        />
                                    </div>}
                                </form.Field>}
                                {/* Sort of hacky, but the div with a mt-3.5 is because of the labels in the other inputs with a line height of 3.5. */}
                                <div className='mt-3.5'>
                                    <Button size={'sm'} className='h-8 py-0 w-full sm:w-auto' variant={'secondary'}>Apply</Button>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-end'>
                            <ServerSideViewOptions
                                table={dataTableRef.current?.getTable()}
                                exportTable={async (e) => {
                                    // // export mode disables all limits on the query, since we need all the data.
                                    const table = dataTableRef.current?.getTable();
                                    if (!table) return;

                                    const isContentSearch = form.state.values.content?.length > 0;

                                    const extraHeaders = getDocumentsTableExtraHeaders(isContentSearch);

                                    const fetchData = async () => {
                                        const preCols = table.getAllColumns();

                                        const rows = table.getPrePaginationRowModel().rows;

                                        const areAllRowsExpanded = table.getIsAllRowsExpanded();
                                        const rowsToExpand: string[] = [];
                                        if (!areAllRowsExpanded) {
                                            for (const row of rows) {
                                                if (row.getIsExpanded()) {
                                                    rowsToExpand.push(row.id);
                                                }
                                            }
                                        }
                                        await fetchDocumentsAndSetSearchResp({ ...query, pageCount: 1 }, true);

                                        // We need to toggle the visibility of the columns to what they were before.
                                        table.getAllColumns().forEach(col => {
                                            const pCol = preCols.find(p => p.id === col.id);
                                            if (pCol) {
                                                col.toggleVisibility(pCol.getIsVisible());
                                            }
                                        });

                                        // we need to re-apply expanded rows.
                                        if (areAllRowsExpanded) {
                                            table.toggleAllRowsExpanded();
                                        } else {
                                            for (const id of rowsToExpand) {
                                                table.getRow(id).toggleExpanded();
                                            }
                                        }
                                    };

                                    const cleanupAndReset = async () => {
                                        await fetchDocumentsAndSetSearchResp({ ...query, pageCount: 1 }, false);
                                    };

                                    if (dataTableRef.current) {
                                        await dataTableRef.current.exportTable(e, extraHeaders, fetchData, cleanupAndReset);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {canDoMultiOrgSearches &&
                        <div className='flex flex-col items-start space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4'>
                            <form.Field name={'isMultiOrg'}>
                                {(field) => <div className='flex flex-col items-start h-full'>
                                    <NavigationDropdownSectionEntryLabel className='px-0 mt-0 text-primary'>
                                        MULTI-ORG SEARCH
                                    </NavigationDropdownSectionEntryLabel>
                                    <div className="flex items-center space-x-2">
                                        <Checkbox
                                            id="terms"
                                            checked={field.state.value}
                                            onCheckedChange={() => {
                                                field.handleChange(!field.state.value);
                                            }} />
                                    </div>
                                </div>}
                            </form.Field>
                            <form.Field name={'multiOrgSearchOrgIds'}>
                                {(field) => <div>
                                    <p className='text-xs font-semibold uppercase text-secondary'>ORGS</p>
                                    <FacetedFilter
                                        disabled={!isMultiOrg}
                                        title='Organizations'
                                        size={'sm'}
                                        options={subscriberOrgs?.map(o => {
                                            return {
                                                label: o.NAME ?? '',
                                                value: o.ORGANIZATIONID.toString()
                                            };
                                        })}
                                        icon={<BuildingsOutlineIcon size={16} />}
                                        selectedValues={field.state.value}
                                        setSelectedValues={(v) => field.handleChange(v)}
                                    />
                                </div>}
                            </form.Field>
                            {isMultiOrg && <form.Field name={'departmentIds'}
                            >
                                {(field) => <div>
                                    <p className='text-xs font-semibold uppercase text-secondary'>Department</p>
                                    <InformationHierarchyFacetedFilter
                                        title='Departments'
                                        size={'sm'}
                                        options={getMultiOrgDepartmentOptions()}
                                        icon={<DepartmentShadowIcon size={16} />}
                                        selectedValues={field.state.value}
                                        setSelectedValues={(v) => field.handleChange(v)}
                                    />
                                </div>}
                            </form.Field>}
                        </div>}
                </form>
                <DocumentRevisionTable
                    columns={columns}
                    docRevs={getDocRevEntries(docRevs ?? [])}
                    loading={loading === LoadingStatuses.LOADING}
                    limitTableHeight={false}
                    manualMode={true}
                    contentFilter={form.state.values.content}
                    exportAsFileName='Search Results'
                    sorting={sorting}
                    onSortingChange={setSorting}
                    ref={dataTableRef}
                />
                <div className='flex flex-row items-center w-full py-2'>
                    <PaginationTools
                        rowCount={searchRes?.docCount ?? 0}
                        curPage={pageCount}
                        curPageSize={pageSize}
                        pageCount={Math.ceil((searchRes?.docCount ?? pageSize) / pageSize)}
                        onPageChange={(page) => setQuery({ ...query, pageCount: page })}
                        onPageSizeChange={(size) => setQuery({ ...query, pageSize: size })}
                    />
                </div>
            </div>
        </>
    );
};
