import { useContext, useEffect } from 'react';
import queryString from 'query-string';
import UserBundleContext from '@/context/UserBundleContext';
import { NavigationMenu, NavigationMenuList } from '@/ui/ui/navigation-menu';
import { HeaderOrganizationDropdown } from './HeaderOrganizationDropdown';
import { HeaderUserDropdown } from './userdropdown/HeaderUserDropdown';
import { LucidocLogoIcon } from '@/ui/icons/LucidocLogo';
import { HeaderInternalDropdown } from './HeaderInternalDropdown';
import { SearchIcon } from 'lucide-react';
import { useHistory } from 'react-router-dom';
import { useOrgBaseURL } from '@/hooks/useOrgBaseURL';
import { AskAQuestionDialog } from './orgdropdown/AskAQuestionDialog';
import { Input } from '@/ui/ui/input';
import { useForm } from '@tanstack/react-form';
import { SearchQuery } from '../search/Search2';
import { useBrowserQuery } from '@/hooks/useBrowserQuery';
import { HybridLink } from '@/ui/ui/hybridlink';
import { Button } from '@/ui/ui/button';
import { HeaderDepartmentsDropdown } from './HeaderDepartmentsDropdown';
import { HeaderManualsDropdown } from './HeaderManualsDropdown';
import { HeaderCommitteesDropdown } from './HeaderCommitteesDropdown';
import { HeaderDocumentsDropdown } from './HeaderDocumentsDropdown';
import { useQueryFeature } from '@/hooks/useQueryFeature';

export default function Header() {

    const userBundle = useContext(UserBundleContext);
    const history = useHistory();
    const orgBaseURL = useOrgBaseURL();

    const [, setQuery] = useBrowserQuery<SearchQuery>();

    const handleKeyDown = (e: KeyboardEvent) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
            e.preventDefault();
            history.push(`${orgBaseURL}/search`);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    const form = useForm({
        defaultValues: {
            search: '',
        },
        onSubmit: (values) => {
            history.push(`${orgBaseURL}/search`);
            // parse the search query for stuff like id: or title:.
            const searchQ = values.value.search.trim().toLowerCase();
            const finalQuery: SearchQuery = { 
                title: '',
                documentId: '',
                content: '',
                departmentIds: [],
                manualIds: [],
                documentOwnerIds: [],
                documentStatusIds: [],
                documentTypeDescriptions: [],
                isMultiOrg: false,
                multiOrgSearchOrgIds: [],
                pageSize: 10,
                pageCount: 1
             };
            if (searchQ.startsWith('id:') || searchQ.startsWith('ref:')) {
                const docidQuery = searchQ
                    .replace('id:', '')
                    .replace('ref:', '')
                    // extra trim required because the previous trim doesn't remove the space after the colon.
                    .trim();
                finalQuery.documentId = docidQuery;
                finalQuery.title = '';
            } else {
                finalQuery.title = values.value.search;
            }

            setQuery(finalQuery);
        },
    });

    const hasDepartmentsDropDown = useQueryFeature('FEATURE_MANUALS_DROPDOWN');
    const hasManualsDropDown = useQueryFeature('FEATURE_DEPARTMENTS_DROPDOWN');
    const hasCommitteesDropDown = useQueryFeature('FEATURE_COMMITTEES_DROPDOWN');
    const hasDocumentsDropDown = useQueryFeature('FEATURE_DOCUMENTS_DROPDOWN');

    const queryParams = queryString.parse(window.location.search);
    if (queryParams.noheader) {
        return null;
    }

    const isUserFromLucidocCorp = userBundle.organization?.ORGANIZATIONID === 10440;

    

    return (
        <div className='flex flex-row justify-between w-full border border-b-2 border-border bg-background'>
            <div className='px-3 items-center justify-center cursor-pointer flex'>
                <HybridLink external={false} href={'/landing'}>
                    <LucidocLogoIcon size={24} />
                </HybridLink>
                <div
                    className='pl-3'
                >
                    <NavigationMenu alignment='alignLeft'>
                        <NavigationMenuList>
                            {
                                hasDepartmentsDropDown &&
                                    <HeaderDepartmentsDropdown />
                            }
                            {
                                hasManualsDropDown &&
                                    <HeaderManualsDropdown />
                            }
                            {
                                hasCommitteesDropDown &&
                                    <HeaderCommitteesDropdown />
                            }
                            {
                                hasDocumentsDropDown &&
                                    <HeaderDocumentsDropdown organization={userBundle.organization!}/>
                            }
                        </NavigationMenuList>
                    </NavigationMenu>
                </div>
            </div>
            <div className='flex flex-row items-center'>
                <NavigationMenu alignment='alignRight'>
                    <NavigationMenuList className=''>
                        {/* These Internal and Search dropdowns can't be in the same "NavigationMenu" 
                        or the hidden one gives it a offset, and the menu looks wacky. */}
                        {isUserFromLucidocCorp && <NavigationMenu alignment={'alignLeft'}>
                            <HeaderInternalDropdown />
                        </NavigationMenu>}
                        {/* <NavigationMenu alignment={'alignLeft'}>
                            <HeaderSearch />
                        </NavigationMenu> */}
                        <form
                            className='hidden xs:block'
                            onSubmit={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                form.handleSubmit();
                            }}>
                            <form.Field name={'search'}>
                                {(field) => {
                                    return <Input
                                        type={'search'}
                                        name={field.name}
                                        value={field.state.value}
                                        onBlur={field.handleBlur}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                        placeholder='Search documents'
                                        icon={<SearchIcon size={16} className='text-foreground' />}
                                    />;
                                }}
                            </form.Field>
                        </form>
                        <Button variant={'ghost'} size={'md'} onClick={() => history.push(`${orgBaseURL}/search`)}
                            className='cursor-pointer flex xs:hidden flex-row items-center justify-between p-1.5 rounded-md ring-1 ring-border'>
                            <SearchIcon size={16} className='text-primary' />
                        </Button>
                        <HeaderOrganizationDropdown organization={userBundle.organization} />
                        <HeaderUserDropdown />
                    </NavigationMenuList>
                </NavigationMenu>
                {/* Read comments inside of AskAQuestionDialog to know why it is here. */}
                <AskAQuestionDialog />
            </div>
        </div>
    );
}
