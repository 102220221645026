// Has to live here because server has access to frontend code, but not vice versa.
// If you update this, you have to also upate the preferences schema for the backend or the update requests etc wont work.
// Check the preferences.ts file in the routes/API folder.
export interface Preferences {
    // Homepage
    homepageTab: 'overview' | 'departments' | 'manuals'
    landingPageFeature: 'dashboard' | 'docmanager' | 'committees' | 'my-education' | 'accreditation-manager',
    theme: 'light' | 'dark' | 'system'
    manualsExpandedByDefault: boolean
    departmentsExpandedByDefault: boolean
    searchManualColumnShownByDefault: boolean
    searchDepartmentColumnShownByDefault: boolean
}

export const DEFAULT_PREFERENCES: Preferences = {
    homepageTab: 'overview',
    landingPageFeature: 'dashboard',
    theme: 'system',
    manualsExpandedByDefault: true,
    departmentsExpandedByDefault: true,
    searchManualColumnShownByDefault: false,
    searchDepartmentColumnShownByDefault: false,
};

